export const calculateDayKwh = (measurements) => {
    const groupedByDay = {};
  
    measurements.forEach(measurement => {
      const date = new Date(measurement.data);
      // Utwórz klucz na podstawie daty, zaokrąglonej do dnia (ignoruj godziny, minuty, sekundy)
      const dayKey = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
  
      if (!groupedByDay[dayKey]) {
        groupedByDay[dayKey] = [];
      }
  
      groupedByDay[dayKey].push(measurement.wat);
    });
  
    // Obliczanie sumy mocy (w watach) dla każdego dnia i przeliczanie jej na kWh
    const totalKwhByDay = Object.keys(groupedByDay).map((day) => {
      const wats = groupedByDay[day];
      const totalWatts = wats.reduce((acc, curr) => acc + curr, 0);
      // W tym przypadku nie obliczamy średniej, lecz sumę watów na dany dzień
      const kwh = (totalWatts / 1000) / wats.length; // Przeliczenie na kWh, dzieląc przez liczbę pomiarów, aby uzyskać średnią godzinową, a następnie przeliczamy na całodniowe zużycie
  
      return {
        data: new Date(parseInt(day)),
        kwh: parseFloat(kwh.toFixed(1)) // Zaokrąglenie do jednego miejsca po przecinku
      };
    });
  
    return totalKwhByDay;
  };
  