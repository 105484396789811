// adressToCoord.js
import axios from 'axios';
import firebaseConfig from '../../firebaseConfig';

const geocodeAddress = async (address) => {
    const firebaseApiKey = firebaseConfig.apiKey;
    const apiKey = firebaseApiKey;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
        const response = await axios.get(url);
        const { data } = response;
        if (data.status === "OK") {
            const { lat, lng } = data.results[0].geometry.location;
            return { lat, lon: lng };
        } else {
            console.error('Geocoding error:', data.status);
            return null;
        }
    } catch (error) {
        console.error('Geocoding error:', error);
        return null;
    }
};

export default geocodeAddress;