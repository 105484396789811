import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './component/pages/Login';
import Dashboard from './component/pages/Dashboard';
import WindReport from './component/pages/WindReport';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/WindReport" element={<WindReport />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;

