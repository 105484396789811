import React, { useState } from 'react';
import AutocompleteInput from '../logicScript/AutocompleteInput';
import '../css/SettingsModal.css';
import { addUser } from '../logicScript/firebaseSavedata'

const SettingsModal = ({ isOpen, onClose, deviceSN, userEmail }) => {

  const [height, setHeight] = useState('');
  const [note, setNote] = useState('');
  const [location, setLocation] = useState('');

if (!isOpen) return null;  

const onSave = async () => {
  console.log('Wysokość:', height);
  console.log('Opis:', note);
  console.log('Lokalizacja:', location);
  console.log('Mail:', userEmail);
  await addUser(location, height, note, deviceSN, userEmail);
  onClose();
};

  const handlePlaceSelect = (address) => {
    setLocation(address); // Aktualizuje stan lokalizacji wybraną opcją
  };
  

  return (
    <div className="settings-modal-backdrop">
      <div className="settings-modal">

        <h2>Ustawienia Urządzenia</h2>

        <p>Numer seryjny: {deviceSN}</p>

        <p>Lokalizacja:</p>
        <AutocompleteInput id="location" placeholder="Wpisz adres" onPlaceSelected={handlePlaceSelect} onLocationChange={setLocation}/>
  
        
        <p>Wysokość:</p>
        <input id="height" type="text" className="input-height" value={height} onChange={(e) => setHeight(e.target.value)}  size="1"/>
          
        <p>Opis:</p>
        <textarea id="note" className="input-note" placeholder="np. 'Lewa połać, kalenica nad sypialnią'" value={note}  onChange={(e) => setNote(e.target.value)}  rows="4" />

        <div className="modal-actions">

          <button className="modal-button" onClick={onSave}>Zapisz</button>
          <button className="modal-button" onClick={onClose}>Zamknij</button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
