// useWeather.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import  geocodeAddress from '../logicScript/adressToCoord'
const useWeather = (loc) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (loc) {
                try {
                    // Rozwiązanie obietnicy i uzyskanie wartości lat i lon
                    const geoData = await geocodeAddress(loc);
                    const lat = geoData.lat;
                    const lon = geoData.lon;
                    const apiKey = '216061e61beba7dd441683b5e46c3c00';
                    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&units=metric&lang=pl`;

                    const response = await axios.get(url);
                    const { clouds, wind, main, weather } = response.data;
                    const result = {
                        zachmurzenie: clouds.all,
                        kierunekWiatru: wind.deg,
                        predkoscWiatru: wind.speed,
                        cisnienieNaPoziomieGruntu: main.grnd_level || main.pressure,
                        wilgotnosc: main.humidity,
                        temperatura: main.temp,
                        ikona: weather[0].icon,
                        opisPogody: weather[0].description,
                    };
                    setData(result);
                } catch (error) {
                    console.error("Błąd podczas pobierania danych pogodowych:", error);
                }
            }
        };

        fetchData();
    }, [loc]);

    return data;
};

export default useWeather;