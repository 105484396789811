import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import firebaseConfig from '../../firebaseConfig';


let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp(); // Pobiera domyślną aplikację, jeśli już istnieje
}

const db = getFirestore(app);

const addUser = async (loc, wysokosc, opis, sn, userEmail) => {
    try {
        // Ścieżka do konkretnego dokumentu urządzenia dla danego użytkownika
        const deviceDocRef = doc(db, "usersData", userEmail, "devices", sn);

        // Tworzenie lub aktualizowanie dokumentu z danymi urządzenia
        await setDoc(deviceDocRef, {
            loc, 
            wysokosc, 
            opis
        });
        console.log(`Dane urządzenia ${sn} zapisane dla:`, userEmail);
    } catch (e) {
        console.error("Błąd podczas zapisu danych urządzenia:", e);
    }
};

export { addUser };
