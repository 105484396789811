import { getApp } from "firebase/app";
import { getStorage, ref, listAll } from "firebase/storage";
import firebaseConfig from '../../firebaseConfig';

export const fetchFoldersFromStorage = async (userEmail) => {
  const { storageBucket } = firebaseConfig;
  const firebaseApp = getApp();

  const storage = getStorage(firebaseApp, `gs://${storageBucket}`);
  //const storage = getStorage(firebaseApp, "gs://ecoflowenergy---windmeter.appspot.com");
  const imagesRef = ref(storage, userEmail);

  try {
    const res = await listAll(imagesRef);
    const folderNames = res.prefixes.map(folderRef => folderRef.name);
    // Zwraca listę nazw podfolderów
    return folderNames;
  } catch (error) {
    console.error("Wystąpił błąd podczas listowania folderów:", error);
    return []; // Zwraca pustą listę w przypadku błędu
  }
};