// calculateAllKwh.js
export const calculateTotalKWh = (measurements) => {
    let totalWatts = 0;
  
    // Sumuj waty z każdego pomiaru
    Object.values(measurements).forEach(measurement => {
      totalWatts += measurement.wat;
    });
  
    // Przelicz waty na kilowaty
    const totalKilowatts = totalWatts / 1000;
  
    // Przelicz na kilowatogodziny, zakładając że każdy pomiar to 1 minuta
    const totalKWh = totalKilowatts / 60;
  
    // Zaokrągl wynik do jednego miejsca po przecinku
    return parseFloat(totalKWh.toFixed(1));
};
