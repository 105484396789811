// calculateEnergyProduction.js
const deviceData = {
    'AN-10': [
        { ms: 0, value: 1 },
        { ms: 1, value: 5 },
        { ms: 2, value: 25 },
        { ms: 3, value: 100 },
        { ms: 4, value: 500 },
        { ms: 5, value: 1200 },
        { ms: 6, value: 2500 },
        { ms: 7, value: 4000 },
        { ms: 8, value: 6000 },
        { ms: 9, value: 8800 },
        { ms: 10, value: 10000 },
        { ms: 11, value: 11000 },
        { ms: 12, value: 11500 },
        { ms: 13, value: 12000 },
        { ms: 14, value: 11700 },
        { ms: 15, value: 11000 },
        { ms: 16, value: 10000 },
        { ms: 17, value: 9000 },
        { ms: 18, value: 7700 },
        { ms: 19, value: 6500 },
        { ms: 20, value: 5500 },
      ],
      'AN-5000': [
        { ms: 0, value: 1 },
        { ms: 1, value: 25 },
        { ms: 2, value: 200 },
        { ms: 3, value: 300 },
        { ms: 4, value: 550 },
        { ms: 5, value: 1100 },
        { ms: 6, value: 2000 },
        { ms: 7, value: 2500 },
        { ms: 8, value: 3000 },
        { ms: 9, value: 4000 },
        { ms: 10, value: 5000 },
        { ms: 11, value: 6000 },
        { ms: 12, value: 6500 },
        { ms: 13, value: 6400 },
        { ms: 14, value: 6000 },
        { ms: 15, value: 5250 },
        { ms: 16, value: 5000 },
      ],
      'AN-3000': [
        { ms: 0, value: 1 },
        { ms: 1, value: 40 },
        { ms: 2, value: 100 },
        { ms: 3, value: 210 },
        { ms: 4, value: 320 },
        { ms: 5, value: 700 },
        { ms: 6, value: 1100 },
        { ms: 7, value: 1750 },
        { ms: 8, value: 2350 },
        { ms: 9, value: 3000 },
        { ms: 10, value: 3500 },
        { ms: 11, value: 3900 },
        { ms: 12, value: 4000 },
        { ms: 13, value: 3750 },
        { ms: 14, value: 3250 },
        { ms: 15, value: 2750 },
        { ms: 16, value: 2500 },
      ],
      'AN-1000': [
        { ms: 0, value: 1 },
        { ms: 1, value: 10 },
        { ms: 2, value: 50 },
        { ms: 3, value: 90 },
        { ms: 4, value: 150 },
        { ms: 5, value: 220 },
        { ms: 6, value: 450 },
        { ms: 7, value: 620 },
        { ms: 8, value: 900 },
        { ms: 9, value: 1100 },
        { ms: 10, value: 1200 },
        { ms: 11, value: 1250 },
        { ms: 12, value: 1300 },
        { ms: 13, value: 1100 },
        { ms: 14, value: 750 },
        { ms: 15, value: 550 },
        { ms: 16, value: 400 },
      ],
    // ... dane dla innych generatorów
  };
  
  export const calculateEnergyProduction = (dataChart, generator) => {
    const interpolacjaLiniowa = (x, x0, y0, x1, y1) => {
      if (x1 === x0) return y0;
      return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
    };
  
    const szacujProdukcje = (speed, charakterystyka) => {
      for (let i = 0; i < charakterystyka.length - 1; i++) {
        const start = charakterystyka[i];
        const end = charakterystyka[i + 1];
        if (speed >= start.ms && speed < end.ms) {
          return interpolacjaLiniowa(speed, start.ms, start.value, end.ms, end.value);
        }
      }
      return charakterystyka[charakterystyka.length - 1].value;
    };
  
    const charakterystyka = deviceData[generator];
  
    const dataGenerator = dataChart.map(item => {
        const speed = item.speed;
        const data = item.data;
        const rawProduction = szacujProdukcje(speed, charakterystyka);
        const wat = Math.floor(rawProduction);
        return { data, wat }; // Każdy obiekt zawiera datę i wyliczoną wartość produkcji
      });
    return dataGenerator;
  };
  