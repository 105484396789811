const firebaseConfig = {
    apiKey: "AIzaSyBoao87aY9VkEQ2iTNQ4TVvAAWll5ZTXCA",
    authDomain: "ecoflowenergy---windmeter.firebaseapp.com",
    databaseURL: "https://ecoflowenergy---windmeter-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ecoflowenergy---windmeter",
    storageBucket: "ecoflowenergy---windmeter.appspot.com",
    messagingSenderId: "50177265083",
    appId: "1:50177265083:web:dd80ad722dc0afd998889e",
    measurementId: "G-VWGN8YYCKF"
  };

  export default firebaseConfig;