import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';
import { GaugeChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GaugeChart, CanvasRenderer]);

const CircleAVG = ({ speedAVG }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    
    if (chartRef.current) {
      var myChart = echarts.init(chartRef.current);
      if (!myChart) {
        myChart = echarts.init(chartRef.current);
      } else {
        myChart.clear(); // Czyści istniejącą konfigurację wykresu przed ponownym użyciem
      }
      const validSpeedAVG = speedAVG ? parseFloat(speedAVG.toFixed(2)) : 0;
      var option = {
        series: [
          {
            type: 'gauge',
            center: ['50%', '60%'],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 30,
            splitNumber: 12,
            itemStyle: {
              color: '#487e40'
            },
            progress: {
              show: true,
              width: 30
            },
            pointer: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 30
              }
            },
            axisTick: {
              distance: -45,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: '#487e40'
              }
            },
            splitLine: {
              distance: -52,
              length: 14,
              lineStyle: {
                width: 3,
                color: '#487e40'
              }
            },
            axisLabel: {
              distance: -20,
              color: '#487e40',
              fontSize: 20
            },
            anchor: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              borderRadius: 8,
              offsetCenter: [0, '-15%'],
              fontSize: 35,
              fontWeight: 'bolder',
              formatter: '{value} m/s',
              color: 'inherit'
            },
            data: [ {value: validSpeedAVG } ]},
          {

            type: 'gauge',
            center: ['50%', '60%'],
            startAngle: 200,
            endAngle: -20,
            min: 0,
            max: 60,
            itemStyle: {
              color: '#487e40'
            },
            progress: {
              show: true,
              width: 8
            },
            pointer: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            },
          }
        ]
      };
      myChart.setOption(option);
      return () => {
        myChart && myChart.dispose();
      };
    }
  }, [speedAVG]); // Dodanie msAVG do tablicy zależności, aby umożliwić reaktywację

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
};

export default CircleAVG;