import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';

const MonthChartData = ({ data }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7));

  useEffect(() => {
    // Najpierw sprawdzamy, czy `data` jest tablicą
    if (!Array.isArray(data)) {
      console.error('Data provided to MonthChartData is not an array:', data);
      return; // Wczesne zakończenie, jeśli `data` nie jest tablicą
    }

    const chartDom = document.getElementById('main-month');
    let myChart = echarts.getInstanceByDom(chartDom);
    if (!myChart) {
      myChart = echarts.init(chartDom);
    }

    const filteredData = data.filter(item => {
      const itemMonth = new Date(item.data).toISOString().slice(0, 7);
      return itemMonth === selectedMonth;
    });

    const transformedData = filteredData.map(item => {
      const date = new Date(item.data);
      const formattedDate = `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}`;
      return [formattedDate, item.kwh];
    });

    const option = {
      title: {
        left: 'center',
        text: 'Wykres Dzienny'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: transformedData.map(item => item[0]) // Używamy sformatowanej daty
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'KWh',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: transformedData.map(item => item[1])
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          throttle: 50
        }
      ]
    };

    myChart.setOption(option);
  }, [data, selectedMonth]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <>
      <input type="month" value={selectedMonth} onChange={handleMonthChange} />
      <div id="main-month" style={{ width: '100%', height: '200px' }}></div>
    </>
  );
};

export default MonthChartData;
