export const csvToVar = (csvContent) => {
  const lines = csvContent.trim().split('\n'); // Dzielimy zawartość na linie
  const data = []; // Będziemy przechowywać przetworzone dane jako obiekty { date, close }

  lines.forEach((line) => {
    const parts = line.split('-m'); // Dzielimy linię na część z czasem i mocą wiatru
    if (parts.length === 2) { // Upewniamy się, że linia jest prawidłowo sformatowana
      let czas = parts[0].trim(); // Czas to część przed '-m', usuwamy białe znaki
      
      // Przekształcenie formatu daty na format akceptowany przez JavaScript
      czas = czas.replace(/(\d{2}):(\d{2}):(\d{2})-(\d{2})-(\d{2})-(\d{4})/, '$6-$5-$4T$1:$2:$3');
      
      const wind = parts[1].trim(); // Wiatr to część po '-m', usuwamy białe znaki
      
      // Tworzymy obiekt z datą i prędkością wiatru, dodajemy do tablicy danych
      data.push({
        data: new Date(czas), // Konwersja stringa na obiekt Date
        speed: parseFloat(wind) // Konwersja stringa na liczbę
      });
    }
  });

  return data; // Zwracamy przetworzone dane gotowe do użycia w wykresie
};
