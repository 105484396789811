import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';

const DayChartData = ({ data }) => {
  // Ustawienie początkowego stanu selectedDate na obecny dzień
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    const chartDom = document.getElementById('main-day');
    let myChart = echarts.getInstanceByDom(chartDom);
    if (!myChart) {
      myChart = echarts.init(chartDom);
    }

    // Sprawdzenie, czy `data` jest tablicą
    const isArray = Array.isArray(data);
    if (!isArray) {
      console.error('Data provided to DayChartData is not an array', data);
      return; // Zakończ wykonywanie, jeśli `data` nie jest tablicą
    }

    const filteredData = data.filter(item => {
      const itemDate = new Date(item.data).toISOString().slice(0, 10);
      return itemDate === selectedDate; // Porównanie czy wybrana data jest taka sama jak data elementu
    });

    const transformedData = filteredData.map(item => {
      // Zwrócenie formatu daty i wartości kWh do wykorzystania na wykresie
      return [item.data, item.kwh];
    });

    const option = {
      title: {
        left: 'center',
        text: 'Wykres Godzinowy'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: transformedData.map(item => new Date(item[0]).toLocaleTimeString())
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'KWh',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: transformedData.map(item => item[1])
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          throttle: 50
        }
      ]
    };

    myChart.setOption(option);
  }, [data, selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <>
      <input type="date" value={selectedDate} onChange={handleDateChange} />
      <div id="main-day" style={{ width: '100%', height: '200px' }}></div>
    </>
  );
};

export default DayChartData;
