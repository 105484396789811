export function getAvgSpeed(data) {
  if (!data || data.length === 0) {
    console.log("Lista jest pusta lub niezdefiniowana");
    return 0;
  }

  const invalidItems = data.filter(item => typeof item.speed !== 'number');
  if (invalidItems.length > 0) {
    console.log("Niektóre elementy nie mają właściwości 'speed' lub nie są liczbami:", invalidItems);
  }

  const sum = data.reduce((acc, item) => acc + item.speed, 0);
  const avg = sum / data.length;

  // Użycie toFixed do konwersji na string z dwoma miejscami po przecinku,
  // a następnie parseFloat do przekonwertowania z powrotem na liczbę.
  return parseFloat(avg.toFixed(2));
}
