import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import '../css/Dashboard.css';
import anemometerImage from '../img/anemometr.png';
import companyLogo from '../img/logo.png';
import avatarImage from '../img/user.png';
import { fetchFoldersFromStorage } from '../logicScript/firebaseStorageService';
import SettingsModal from './SettingsModal'; // Import nowego komponentu
import { readDeviceData } from '../logicScript/firebaseDatabaseRead';

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [deviceSNs, setDeviceSNs] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [currentDeviceSN, setCurrentDeviceSN] = useState('');
  
  // Pobieranie emaila z lokacji, który został przekazany jako stan nawigacji
  const email = location.state?.email;
  const username = email.split('@')[0];

  if (!email) {
    console.error('Brak danych email, przekierowanie do strony logowania.');
    navigate('/');
  }


  const raportGet = (sn) => {
    navigate('/WindReport', { state: { email, sn, ...deviceDetails[sn] } });
  
  }; 

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Problem z wylogowaniem:', error);
      });
  };

  const settingsDevice = (sn) => {
    setCurrentDeviceSN(sn);
    setIsSettingsModalOpen(true);
  };

  useEffect(() => {
    (async () => {
      const fetchedSNs = await fetchFoldersFromStorage(email);
      setDeviceSNs(fetchedSNs);
      
      const details = {};
      for (const sn of fetchedSNs) {
        const data = await readDeviceData(email, sn);
        details[sn] = data;
      }
      setDeviceDetails(details);
    })();
  }, [email]);

  return (
    <div className="dashboard-container">
      <nav className="dashboard-nav" style={{ backgroundColor: '#487e40' }}>
        <img src={companyLogo} alt="Logo Firmy" className="company-logo"/>
      </nav>
      <div className="user-info">
        <img src={avatarImage} alt="Avatar" className="user-avatar" />
        <h1>Cześć, {username}.</h1>
        <p>Obserwuj na bieżąco dane dotyczące prędkości wiatru i predykcji wytwarzania energii z natury!</p>
      </div>
      <div className="user-actions">
        
      </div>
      <hr className="divider"/>
      <div className="device-container">
        {deviceSNs.map((sn, index) => (
          <div key={sn} className="device-box">
            <h2>Urządzenie: {sn}</h2>
            <img src={anemometerImage} alt="Anemometr" className="anemometer-image"/>
            <div className="device-info">
              <p>Aktualizacja Danych : 23:00</p>
              <p>Status : OK</p>
              <p>Opis: {deviceDetails[sn]?.opis || '---'}</p>
              <p>Lokalizacja : {deviceDetails[sn]?.loc || '---'}</p>
              <p>Wysokość: {deviceDetails[sn]?.wysokosc || '---'}</p>
            </div>
            <button onClick={() => settingsDevice(sn)} className="report-button">Ustawienia</button>
            <button onClick={() => raportGet(sn)} className="report-button">Zobacz pełny raport</button>
          </div>
        ))}
      </div>
      <hr className="divider"/>
      <div className="logout-button-container">
      <button onClick={handleLogout} className="user-action-button">Wyloguj</button>
      </div>
      <SettingsModal 
        isOpen={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        deviceSN={currentDeviceSN}
        userEmail={email}
      />
    </div>
  );
};

export default Dashboard;