import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';


const DaychartData = ({ data }) => {
  // Ustawienie początkowego stanu selectedDate na obecną datę
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const chartDom = document.getElementById('hour_chart_main');
    let myChart = echarts.getInstanceByDom(chartDom);
    if (!myChart) {
      myChart = echarts.init(chartDom);
    }

    const filteredData = data.filter(item => {
      const itemDate = new Date(item.data);
      const selectedDateObj = new Date(selectedDate);
      itemDate.setHours(0, 0, 0, 0);
      selectedDateObj.setHours(0, 0, 0, 0);
      return itemDate.getTime() === selectedDateObj.getTime();
    });


    const transformedData = filteredData.map(item => {
      const date = new Date(item.data);
      const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
      return [formattedDate, item.speed];
    });

    const option = {
      title: {
        left: 'center',
        text: 'Wykres Minutowy'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: transformedData.map(item => item[0])
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'Średnia Prędkość',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: transformedData.map(item => item[1])
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          throttle: 50
        }
      ]
    };

    myChart.setOption(option);
  }, [data, selectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <>
      <input type="date" value={selectedDate} onChange={handleDateChange} />
      <div id="hour_chart_main" style={{ width: '100%', height: '350px' }}></div>
    </>
  );
};

export default DaychartData;
