import { getFirestore, doc, getDoc } from "firebase/firestore";
import { initializeApp, getApps, getApp } from "firebase/app";
import firebaseConfig from '../../firebaseConfig';


let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp(); // Pobiera domyślną aplikację, jeśli już istnieje
}

const db = getFirestore(app);

const readDeviceData = async (userEmail, deviceId) => {

    const docRef = doc(db, "usersData", userEmail, "devices", deviceId);
  
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data(); // Zwraca dane odczytane z dokumentu
      } else {
        console.log("Nie znaleziono dokumentu!");
        return null; // Możesz zwrócić null lub odpowiednią wartość, gdy dokument nie istnieje
      }
    } catch (error) {
      console.error("Błąd podczas odczytu dokumentu:", error);
      throw error; // Rzucenie wyjątku lub obsłużenie błędu w inny sposób
    }
  };
  export { readDeviceData };