// Lib
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Charts
// Estymacja Kwh
import HourlyKwhChart from '../charts/calculateHourKwhCharts';
import DailyKwhChart from '../charts/calculateDayKwhCharts';
import MonthlyKwhChart from '../charts/calculateMonthKwhCharts';

import PickDataTurbine  from '../charts/pickTurbine';
// Historia Pomiaru
import HourchartData from '../charts/minuteDatachart';
import CircleAVG  from '../charts/circleAVG';
import DaychartData from '../charts/hourDatachart';

// Historia Danych
import AllDatachart  from '../charts/allDatachart';
import AlldataPowergen from '../charts/allpowerGenchart';


// Logic
import { firebaseGetCSV } from '../logicScript/firebaseGetCSV';
import { csvToVar } from '../logicScript/csvToVar';
import useWeather from '../logicScript/useWeather';
// Logic Ms Calculate
import { getAvgSpeed } from '../logicScript/getAvgSpeed';
import { calculateHourlyAverageSpeed } from '../logicScript/calculateMs/calculateAvgMsHour';
// Logic Kwh Calculate
import { calculateEnergyProduction } from '../logicScript/calculateEnergyProduction'; //przelicza ms na waty
import { calculateTotalKWh } from '../logicScript/calculateKwh/calculateAllKwh';
import { calculateHourKwh } from '../logicScript/calculateKwh/calculateHourKwh';
import { calculateDayKwh } from '../logicScript/calculateKwh/calculateDayKwh';
import { calculateMonthKwh } from '../logicScript/calculateKwh/calculateMonthKwh'

// CSS
import '../css/WindReport.css';
import '../css/WeatherWidget.css'
import '../css/deviceWidget.css'

const WindReport = () => {
  const location = useLocation();
  const { email, sn, ...deviceDetails } = location.state || {};
  const { loc, opis, wysokosc } = deviceDetails;
  const weather = useWeather(loc);
  const [chartData, setChartData] = useState([]);
  const [chartDataWat, setchartDataWat] = useState([]);
  const [speedAVG, setSpeedAVG] = useState(null);
  const [speedMsAvgHour, setSpeedMsAvgHour] = useState([]);
  const [totalKWH, setTotalKWH] = useState([]);
  const [hourKWH, setHourKWH] = useState([]);
  const [dayKWH, setDayKWH] = useState([]);
  const [monthKWH, setMonthKWH] = useState([]);

  const [generatorType, setGeneratorType] = useState('AN-5000'); // Domyślny typ generatora
  

  useEffect(() => {
    const getCsvData = async () => {
      try {
        const data = await firebaseGetCSV();
        const varData = csvToVar(data);/////////zmienna która ma dane z CSV
        setChartData(varData);/////////delkaracja do chartData state
        const msAVG = getAvgSpeed(varData);
        setSpeedAVG(msAVG);

        const avgSpeedHour = calculateHourlyAverageSpeed(varData);
        setSpeedMsAvgHour(avgSpeedHour);

        const dataGenerator = calculateEnergyProduction(varData, generatorType); 
        setchartDataWat(dataGenerator);

        const totalKWh = calculateTotalKWh(dataGenerator);
        setTotalKWH(totalKWh);

        const hourlyKwh = calculateHourKwh(dataGenerator);
        setHourKWH(hourlyKwh);


        const dailyKwh = calculateDayKwh(dataGenerator);
        setDayKWH(dailyKwh);

        const monthlyKwh = calculateMonthKwh(dataGenerator);
        setMonthKWH(monthlyKwh);

        

      } catch (error) {
        console.error("Nie udało się pobrać pliku CSV", error);
      }
    };
    getCsvData();

  }, [generatorType]);

  function wind_direction(degrees) {
    const directions = ['Północ', 'Północny Wschód', 'Wschód', 'Południowy Wschód', 'Południe', 'Południowy Zachód', 'Zachód', 'Północny Zachód'];
    const index = Math.round(((degrees %= 360) < 0 ? degrees + 360 : degrees) / 45) % 8;
    return directions[index];
  }

  return (
  <div className="wind-report">
      {/* Górna BELKA */}
  <div className="top_data">
    <div className="generated_energy">
      <h2>Generowana Energia</h2>
      <p><span>Wytworzonych KWh łącznie :</span> {totalKWH}</p>
      <HourlyKwhChart data={hourKWH}/>
      <DailyKwhChart data={dayKWH}/>
      <MonthlyKwhChart data={monthKWH}/>
    </div>

    <div className="weather_data">
      {weather ? (
        <div>
          <h2>
          <p>Dane Pogodowe z Lokalizacji</p>
          <img src={`http://openweathermap.org/img/w/${weather.ikona}.png`} alt="Ikona pogody" style={{ width: '100px', height: '100px' }}/>
          </h2>
          <p><span>Lokalizacja:</span> {loc}</p>
          <div class="widgetWeather">
            <div class="widgetWeatherRow">
              <div class="box">
                <p><span>Temperatura</span>
                <p></p>
                {weather.temperatura}°C</p>
              </div>
              <div class="box">
              <p><span>Kierunek wiatru</span>
              <p></p>
              {wind_direction(weather.kierunekWiatru)}</p>
              </div>
              <div class="box">
              <p><span>Wilgotność</span>
              <p></p> 
              {weather.wilgotnosc}%</p>
              </div>
            </div>
            <div class="widgetWeatherRow">
              <div class="box1">
                <p><span>Ciśnienie</span>
                <p></p>
                {weather.cisnienieNaPoziomieGruntu} hPa</p>
              </div>
              <div class="box1">
              <p><span>Zachmurzenie</span>
              <p></p>
              {weather.zachmurzenie}%</p>
              </div>
            </div>
          </div> 

          </div>) : ( <p>Ładowanie danych...</p> )}
    </div>

    <div className="device_data">
      <h2>Dane Urządzenia  Pomiarowego</h2>
      <div class="widgetDevice">
        <div class="widgetDeviceRow">
          <div class="boxDevice">
          <p><span>Numer Seryjny Urzadzenia</span></p>
          <p>{sn}</p>
          </div>
          <div class="boxDevice">
          <p><span>Lokalizacja</span></p>
          <p>{loc}</p>
          </div>
          <div class="boxDevice">
          <p><span>Użytkownik</span></p>
          <p>{email}</p>
          </div>
        </div>
      <div class="widgetDeviceRow">
        <div class="boxDevice">
          <p><span>Lokalizacja</span></p>
          <p>{loc}</p>
        </div>
        <div class="boxDevice">
          <p><span>Wysokość</span></p>
          <p>{wysokosc}m.</p>
        </div>
        <div class="boxDevice">
          <p><span>Opis</span></p>
          <p>{opis}</p>
        </div>
      </div>
      </div>
    </div>

    <div className="turbine_data">
      <h2>Wybierz typ Turbiny</h2>
      <PickDataTurbine onDeviceSelect={setGeneratorType}/>
    </div>

  </div>
        {/* ŚRODKOWA BELKA */}
  <div className="middle_data">

    <div className="hour_chart">
      <HourchartData data={chartData} />
    </div>
    
    <div className="avg_speed">
      <h2>Średnia predkość z Dostępnych Danych</h2>
      <CircleAVG speedAVG={speedAVG}/>
    </div>

    <div className="day_chart">
      <DaychartData data={speedMsAvgHour} />
    </div>
  </div>

     {/* DOLNA BELKA */}   
  <div className="bottom_data">

    <div className="all-data_chart">
      <AllDatachart data={chartData}/>
    </div>
    <div className="all-data_chart">
      <AlldataPowergen data={chartDataWat} />
    </div>

  </div>

</div>
  );
  
};

export default WindReport;

