import { initializeApp, getApps, getApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import firebaseConfig from '../../firebaseConfig';

// Inicjalizacja Firebase
let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApp(); // Pobiera domyślną aplikację, jeśli już istnieje
}

export const firebaseGetCSV = async () => {
  const storage = getStorage(firebaseApp); // Użyj zainicjalizowanej aplikacji Firebase 
  const fileRef = ref(storage, 'test@gmail.com/23S23DD21/wind.csv'); ////////// sciąganie odpowiedniego pliku z  databse!

  try {
    const url = await getDownloadURL(fileRef); // Pobierz URL pliku
    // Opcjonalnie: Pobierz zawartość pliku, np. jeśli to plik CSV
    const response = await fetch(url);
    const text = await response.text(); // Pobierz zawartość jako tekst
    return text; // Zwróć zawartość pliku
  } catch (error) {
    console.error("Błąd podczas pobierania pliku:", error);
    throw error;
  }
};
