export const calculateHourlyAverageSpeed = (measurements) => {
    const groupedByHour = {};

    measurements.forEach(measurement => {
        const date = new Date(measurement.data);
        const hourKey = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).getTime();

        if (!groupedByHour[hourKey]) {
            groupedByHour[hourKey] = [];
        }

        groupedByHour[hourKey].push(measurement.speed);
    });

    // Obliczanie średniej prędkości dla każdej godziny
    const averageSpeedByHour = Object.keys(groupedByHour).map((hour) => {
        const speeds = groupedByHour[hour];
        const totalSpeed = speeds.reduce((acc, curr) => acc + curr, 0);
        const averageSpeed = totalSpeed / speeds.length; // Obliczanie średniej

        return {
            data: new Date(parseInt(hour)),
            averageSpeed: parseFloat(averageSpeed.toFixed(2)) // Zaokrąglenie do dwóch miejsc po przecinku
        };
    });

    return averageSpeedByHour;
};
