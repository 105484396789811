import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Importy Firebase
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import firebaseConfig from '../secure/firebaseConfig';
import '../css/Login.css'; // Zakładam, że style są zapisane w pliku Login.css
import './Dashboard'

// Inicjalizacja Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Logowanie zakończone sukcesem
        console.log('Zalogowano użytkownika:', userCredential.user);
        navigate('/dashboard', { state: { email: userCredential.user.email } });
      })
      .catch((error) => {
        // Błąd logowania
        console.error('Błąd logowania:', error.message);
        setError('Błędne dane logowania'); // Ustawienie stanu błędu
        setTimeout(() => setError(''), 5000);
      });
  };

  
  return (
    <div className="login-container">
      <video autoPlay loop muted className="video-background">
        <source src="https://ecoflowenergy.pl/wp-content/uploads/2023/07/Projekt-bez-nazwy-2.mp4" type="video/mp4" />
      </video>
      <div className="login-form">
        <h2>Logowanie</h2>
        <h4>Wind Meter Dashboard</h4>
        <form onSubmit={(e) => e.preventDefault()}>
          <div>
            <label>Email:</label>
            <input
              autoComplete='username'
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div>
            <label>Hasło:</label>
            <input
              type="password"
              autoComplete='current-password'
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <button type="button" onClick={handleLogin}>
            Zaloguj się
          </button>
          {error && <div className="login-error">{error}</div>} {/* Wyświetlamy błąd jeśli istnieje */}
          <div className="powered-by">
          Powered by ecoflowenergy.pl
        </div>
        </form>
      </div>
      
    </div>
  );
};

export default Login;
