import React, { useState, useEffect } from 'react';
import * as echarts from 'echarts';

const PickDataTurbine = ({ onDeviceSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState('AN-5000'); // Ustawienie domyślnego urządzenia
  const [dropdownLabel, setDropdownLabel] = useState('AN-5000 - 5000W');

  // Kompletne dane charakterystyki dla każdego urządzenia
  const values = [
    { id: 'AN-1000', power: '1000W' },
    { id: 'AN-3000', power: '3000W' },
    { id: 'AN-5000', power: '5000W' },
    { id: 'AN-10', power: '10KW' },
  ];

  useEffect(() => {
    const chartDom = document.getElementById('mainChart');
    let myChart = echarts.getInstanceByDom(chartDom); // Pobiera instancję jeśli istnieje
    if (!myChart) {
      myChart = echarts.init(chartDom); // Inicjuje nową instancję, jeśli nie istnieje
    }

    // Definiowanie danych dla wykresu na podstawie wybranego urządzenia
    const deviceData = {
      'AN-10': [
        { hour: 0, value: 0 },
        { hour: 1, value: 0 },
        { hour: 2, value: 0 },
        { hour: 3, value: 0 },
        { hour: 4, value: 500 },
        { hour: 5, value: 1200 },
        { hour: 6, value: 2500 },
        { hour: 7, value: 4000 },
        { hour: 8, value: 6000 },
        { hour: 9, value: 8800 },
        { hour: 10, value: 10000 },
        { hour: 11, value: 11000 },
        { hour: 12, value: 11500 },
        { hour: 13, value: 12000 },
        { hour: 14, value: 11700 },
        { hour: 15, value: 11000 },
        { hour: 16, value: 10000 },
        { hour: 17, value: 9000 },
        { hour: 18, value: 7700 },
        { hour: 19, value: 6500 },
        { hour: 20, value: 5500 },
      ],
      'AN-5000': [
        { hour: 0, value: 0 },
        { hour: 1, value: 0 },
        { hour: 2, value: 200 },
        { hour: 3, value: 300 },
        { hour: 4, value: 550 },
        { hour: 5, value: 1100 },
        { hour: 6, value: 2000 },
        { hour: 7, value: 2500 },
        { hour: 8, value: 3000 },
        { hour: 9, value: 4000 },
        { hour: 10, value: 5000 },
        { hour: 11, value: 6000 },
        { hour: 12, value: 6500 },
        { hour: 13, value: 6400 },
        { hour: 14, value: 6000 },
        { hour: 15, value: 5250 },
        { hour: 16, value: 5000 },
      ],
      'AN-3000': [
        { hour: 0, value: 0 },
        { hour: 1, value: 0 },
        { hour: 2, value: 100 },
        { hour: 3, value: 210 },
        { hour: 4, value: 320 },
        { hour: 5, value: 700 },
        { hour: 6, value: 1100 },
        { hour: 7, value: 1750 },
        { hour: 8, value: 2350 },
        { hour: 9, value: 3000 },
        { hour: 10, value: 3500 },
        { hour: 11, value: 3900 },
        { hour: 12, value: 4000 },
        { hour: 13, value: 3750 },
        { hour: 14, value: 3250 },
        { hour: 15, value: 2750 },
        { hour: 16, value: 2500 },
      ],
      'AN-1000': [
        { hour: 0, value: 0 },
        { hour: 1, value: 10 },
        { hour: 2, value: 50 },
        { hour: 3, value: 90 },
        { hour: 4, value: 150 },
        { hour: 5, value: 220 },
        { hour: 6, value: 450 },
        { hour: 7, value: 620 },
        { hour: 8, value: 900 },
        { hour: 9, value: 1100 },
        { hour: 10, value: 1200 },
        { hour: 11, value: 1250 },
        { hour: 12, value: 1300 },
        { hour: 13, value: 1100 },
        { hour: 14, value: 750 },
        { hour: 15, value: 550 },
        { hour: 16, value: 400 },
      ],
    };

    if (selectedDevice) {
      const option = {
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: deviceData[selectedDevice].map(item => item.hour.toString() + 'm/s'),
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} W',
            fontSize: 10,
          }
        },
        grid: {
          left: '10%',  // Adjust this value as needed to provide more space for y-axis labels
          right: '10%',
          bottom: '3%',
          containLabel: true // This property ensures that the labels are contained within the chart area
        },
        series: [{
          data: deviceData[selectedDevice].map(item => item.value),
          type: 'line',
          areaStyle: {}
        }]
      };

      myChart.setOption(option);
    }
  }, [selectedDevice]);

  const handleItemClick = (item) => {
    setSelectedDevice(item.id);
    setIsOpen(false);
    setDropdownLabel(`${item.id} - ${item.power}`);
    onDeviceSelect(item.id); // Wywołanie przekazanej funkcji callback z wybranym ID urządzenia
  };

  return (
    <div>
      <div onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer', padding: '10px', backgroundColor: '#ddd' }}>
        {dropdownLabel}
      </div>
      {isOpen && (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {values.map((item) => (
            <li key={item.id} onClick={() => handleItemClick(item)} style={{ padding: '8px 10px', backgroundColor: '#eee', borderBottom: '1px solid #ddd', cursor: 'pointer' }}>
              {item.id} - {item.power}
            </li>
          ))}
        </ul>
      )}
      <p>Charakterystyka Turbiny:</p>
      
      <div id="mainChart" style={{ height: '200px', width: '100%'}}></div>
    </div>
  );
};

export default PickDataTurbine;
