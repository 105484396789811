export const calculateHourKwh = (measurements) => {
  const groupedByHour = {};

  measurements.forEach(measurement => {
    const date = new Date(measurement.data);
    const hourKey = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).getTime();

    if (!groupedByHour[hourKey]) {
      groupedByHour[hourKey] = [];
    }

    groupedByHour[hourKey].push(measurement.wat);
  });

  // Obliczanie sumy mocy (w watach) dla każdej godziny i przeliczanie jej na kWh
  const totalKwhByHour = Object.keys(groupedByHour).map((hour) => {
    const wats = groupedByHour[hour];
    const totalWatts = wats.reduce((acc, curr) => acc + curr, 0);
    const averageWatts = totalWatts / wats.length; // Średnia moc w watach
    const kwh = (averageWatts / 1000); // Przeliczenie na kWh

    return {
      data: new Date(parseInt(hour)),
      kwh: parseFloat(kwh.toFixed(1)) // Zaokrąglenie do jednego miejsca po przecinku
    };
  });

  return totalKwhByHour;
};
