import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts/core';

const ProductionEst = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    const dates = data.map(item => {
      const date = new Date(item.data);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    });
    const wats = data.map(item => item.wat);
    
    let option = {
      tooltip: {
        trigger: 'axis',
        position: function (pt) {
          return [pt[0], '10%'];
        }
      },
      title: {
        left: 'center',
        text: 'Historia Przybliżonej Produkcji'
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dates
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100
        }
      ],
      series: [
        {
          name: 'Produkcja [W]',
          type: 'line',
          symbol: 'none',
          sampling: 'lttb',
          itemStyle: {
            color: 'rgb(92,156,82)'
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgb(92,156,82)'
              },
              {
                offset: 1,
                color: 'rgb(255,255,1)'
              }
            ])
          },
          data: wats
        }
      ]
    };

    myChart.setOption(option);

    return () => { myChart.dispose(); };
  }, [data]);

  return (
    <div ref={chartRef} style={{ width: '98%', height: '100%' }}> {/* Adjust height as needed */}
    </div>
  );
};

export default ProductionEst;
