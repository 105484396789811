import React, { useRef, useEffect } from 'react';

function AutocompleteInput({ id, placeholder, onPlaceSelected }) {
  const inputRef = useRef(null);

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ['address'],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        onPlaceSelected(place.formatted_address); // Teraz przekazuje pełny adres
      }
    });
  }, [onPlaceSelected]);

  return (
    <input ref={inputRef} id={id} type="text" placeholder={placeholder} />
  );
}

export default AutocompleteInput;
