import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts/core';

const YearChartData = ({ data }) => {
  // Ustawienie stanu na obecny rok
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    const chartDom = document.getElementById('main-year');
    let myChart = echarts.getInstanceByDom(chartDom);
    if (!myChart) {
      myChart = echarts.init(chartDom);
    }

    if (!Array.isArray(data)) {
      console.error('Data provided to YearChartData is not an array:', data);
      return;
    }

    const filteredData = data.filter(item => {
      const itemYear = new Date(item.data).getFullYear().toString();
      return itemYear === selectedYear; // Filtracja po roku
    });

    const transformedData = filteredData.map(item => {
      // Formatowanie miesiąca do postaci MM.RRRR
      const date = new Date(item.data);
      const formattedDate = `${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
      return [formattedDate, item.kwh];
    });

    const option = {
      title: {
        left: 'center',
        text: 'Wykres Miesięczny'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: transformedData.map(item => item[0]) // Miesiące roku
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'KWh',
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: transformedData.map(item => item[1])
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          throttle: 50
        }
      ]
    };

    myChart.setOption(option);
  }, [data, selectedYear]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <>
      <input type="number" value={selectedYear} onChange={handleYearChange} min="2000" max="2099" />
      <div id="main-year" style={{ width: '100%', height: '200px' }}></div>
    </>
  );
};

export default YearChartData;
