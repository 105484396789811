export const calculateMonthKwh = (measurements) => {
    const groupedByMonth = {};
  
    measurements.forEach(measurement => {
      const date = new Date(measurement.data);
      const monthKey = new Date(date.getFullYear(), date.getMonth()).getTime();
  
      if (!groupedByMonth[monthKey]) {
        groupedByMonth[monthKey] = [];
      }
  
      groupedByMonth[monthKey].push(measurement.wat);
    });
  
    // Obliczanie sumy mocy (w watach) dla każdego miesiąca i przeliczanie jej na kWh
    const totalKwhByMonth = Object.keys(groupedByMonth).map((month) => {
      const wats = groupedByMonth[month];
      const totalWatts = wats.reduce((acc, curr) => acc + curr, 0);
      // Tutaj zmieniamy podejście - zamiast liczyć średnią, sumujemy całkowitą moc w watach
      // przez cały miesiąc, a następnie dzielimy przez 1000, aby uzyskać kWh
      const kwh = (totalWatts / 1000); // Przeliczenie na kWh
  
      return {
        data: new Date(parseInt(month)),
        kwh: parseFloat(kwh.toFixed(1)) // Zaokrąglenie do jednego miejsca po przecinku
      };
    });
  
    return totalKwhByMonth;
  };
  